<template>
  <div>
    <b-card>
      <div class="app_title_box">
        <h4>Reported Reviews</h4>
        <!-- <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="showModal"
        >
          New
        </b-button> -->
      </div>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="reviewItems"
        @row-clicked="showDetails"
      >
        <!-- <template #cell(cover)="data">
          <b-avatar :src="getImage(data.item.business_profile.cover)" />
        </template> -->
        <template #cell(business_name)="data">
          {{ data.value }}
        </template>
        <template #cell(reviewer)="data">
          {{ data.item.reviewer.first_name }} {{ data.item.reviewer.last_name }}
        </template>
        <template #cell(rating)="data">
          <b-form-rating
            variant="warning"
            no-border
            :value="data.value"
            readonly
            inline
            class="raiting_box"
            style="border:none !important; padding-left: 0 !important;"
          />
        </template>
        <template
          #cell(created_at)="data"
        >
          <span>{{ date(data.value) }}</span>
        </template>
        <template
          #cell(status)="data"
        >
          <b-form-select
            v-model="selected = data.value"
            :options="options"
            style="width: 200px"
            @change="statusChange($event, data.item)"
          />
        </template>
      </b-table>
    </b-card>

    <b-modal
      id="app-modal"
      title="Reported Review Details"
      hide-footer
      size="lg"
    >
      <b-card-text v-if="review !== null">
        <div
          class="business_reviw__card"
        >
          <div class="reviewer_view">
            <b-avatar
          v-if="review.reviewer.avatar === null || review.reviewer.avatar === ''"
          variant="success"
          :text="getUserText(review.reviewer.first_name, review.reviewer.last_name)"
          size="50"
        />
        <b-avatar
          v-else
          :src="getImage(review.reviewer.avatar)"
          size="50"
        />
            <div class="review-heading_box">
              <div class="heading">
                <h4>{{ review.reviewer.first_name }} {{ review.reviewer.last_name }}, {{ review.reviewer.profession }}</h4>
                <p style="margin-top: -10px;"> <b-form-rating
                  variant="warning"
                  no-border
                  :value="review.rating"
                  readonly
                  inline
                  class="raiting_box"
                  style="border:none !important; padding-left: 0 !important;"
                /></p>
              </div>
              <div />

            </div>

          </div>
          <div class="mb-3">
            <b-badge
              :variant="review.status === 'active' ? 'light-success':
                review.status === 'reported' ? 'light-danger' : review.status === 'under review' ? 'light-warning' : 'light-secondary'
              "
            >
              Review {{ review.status }}
            </b-badge>
          </div>

          <p class="mb-2">{{ review.review }}</p>
          <div class="row">
            <div class="col-md-4 mb-2">
              <h5>Customer Service</h5>
              <span>{{ review.content.customer_service }}</span>
            </div>

            <div class="col-md-3 mb-2">
              <h5>Pricing</h5>
              <span>{{ review.content.pricing }}</span>
            </div>

            <div class="col-md-3 mb-2">
              <h5>Quality</h5>
              <span>{{ review.content.quality }}</span>
            </div>

            <div class="col-md-4 mb-2">
              <h5>Timeliness</h5>
              <span>{{ review.content.timeliness }}</span>
            </div>

            <div class="col-md-3 mt-2">
              <h5>Recommendation</h5>
              <span>{{ review.content.recommend }}</span>
            </div>
          </div>

          <hr>
          <b-alert
            variant="danger"
            show
          >
            <h4 class="alert-heading">
              Report
            </h4>
            <div class="alert-body">
              <span>{{ review.report }}</span>
            </div>
          </b-alert>
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BButton, BModal, VBModal, BCardText, BTable, BFormSelect, BAvatar, BFormRating, BBadge, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'

export default {
  components: {
    BCard,
    BButton,
    BModal,
    BCardText,
    BTable,
    BFormSelect,
    BAvatar,
    BFormRating,
    BBadge,
    BAlert,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      is_update: false,
      selected: null,
      fields: [{ key: 'id', label: '#ID' }, { key: 'business_name', label: 'Business Name', sortable: true }, { key: 'reviewer', label: 'Reviewer' }, { key: 'rating', label: 'Rating', sortable: true }, { key: 'created_at', label: 'Date', sortable: true }, 'status'],
      items: [],
      options: [
        { value: 'active', text: 'active' },
        { value: 'canceled', text: 'canceled' },
        { value: 'reported', text: 'reported' },
        { value: 'under review', text: 'under review' },
      ],
      review: null,
    }
  },
  computed: {
    reported_reviews() {
      return this.$store.state.review.reviews_by_status
    },
    totalRows() {
      return this.reported_reviews.length
    },
    reviewItems() {
      return this.reported_reviews.map(item => ({
        ...item,
        business_name: `${item.business_profile.name}`,
      }))
    },
  },
  mounted() {
    this.getReviewByStatus()
  },
  methods: {
    getReviewByStatus() {
      this.$store.dispatch('review/getReviewByStatus', 'reported')
    },
    showModal() {
      this.$bvModal.show('app-modal')
    },
    getUserText(firstName, lastName) {
      const firstChar = firstName.charAt(0)
      const lastChar = lastName.charAt(0)
      return firstChar + lastChar
    },
    hide() {
      this.$bvModal.hide('app-modal')
    },
    getImage(image) {
      if (image !== null || image !== '') {
        return process.env.VUE_APP_STORAGE_PATH + image
      }
      return image
    },
    statusChange(status, item) {
      const data = { id: item.id, status }
      this.$store.dispatch('review/updateReviewStatus', data)
      const dom = this
      setTimeout(() => {
        dom.getReviewByStatus()
      }, 200)
    },
    showDetails(item) {
      // this.is_update = true
      this.review = item
      this.showModal()
    },
    date(date) {
      return moment(date).format('ddd DD-MMM-YY, hh:mm a')
    },
  },
}
</script>

<style>

</style>
